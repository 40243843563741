<template>
  <CRow class="overflow-x-auto">
    <div class="text-right col-12 mb-3"></div>
    <div class="table-responsive">
      <table class="table table-dark">
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone number</th>
            <th scope="col">Subject</th>
            <th scope="col">Created At</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item['id']">
            <td>{{ getUserId(index + 1) }}</td>
            <td>{{ item["name"] }}</td>
            <td>{{ item["email"] }}</td>
            <td>{{ item["phone_number"] }}</td>
            <td>{{ item["subject"] }}</td>
            <td>{{ item["created_at"] }}</td>
            <td>
              <CButton
                class="right-create"
                size="sm"
                color="primary"
                @click="showData(item)"
                >View </CButton
              >&nbsp;

              <!-- <CButton
              class="right-create"
              size="sm"
              color="danger"
              @click="deleteContract(item['id'])"
              >Delete</CButton>-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end w-100 mb-3">
      <nav aria-label="Page navigation example" class="mr-3">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('prev')"
              aria-label="Previous"
              id="prev"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: t === current_page }"
            v-for="t in totalPage"
            :key="t"
          >
            <a class="page-link" @click="onPageLoad(t)">{{ t }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('next')"
              aria-label="Next"
              id="next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
      <select v-model="selected" @change="onSelect" class="px-3">
        <option>5</option>
        <option :selected="true">10</option>
        <option>50</option>
      </select>
    </div>
    <!-- <ContectUsViewPopUp :modalShow="modalShow" :data="popupData" v-if="modalShow" /> -->

    <CModal
      :show.sync="modalShow"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
      v-if="popupData"
    >
      <!-- {{ popupData }} -->
      <div class="row py-1">
        <div class="col-md-2">Id</div>
        <div class="col-md-10">{{ popupData.id }}</div>
      </div>
      <div class="row py-1">
        <div class="col-md-2">Name</div>
        <div class="col-md-10">{{ popupData.name }}</div>
      </div>
      <div class="row py-1">
        <div class="col-md-2">Phone number</div>
        <div class="col-md-10">{{ popupData.phone_number }}</div>
      </div>
      <div class="row py-1">
        <div class="col-md-2">Subject</div>
        <div class="col-md-10">{{ popupData.subject }}</div>
      </div>
      <div class="row py-1">
        <div class="col-md-2">Description</div>
        <div class="col-md-10">{{ popupData.description }}</div>
      </div>
      <div class="row py-1">
        <div class="col-md-2">Created at</div>
        <div class="col-md-10">{{ popupData.created_at }}</div>
      </div>

      <template #header>
        <h6 class="modal-title">Contact Us</h6>
        <CButtonClose @click="modalShow = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="modalShow = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import { getContectuss } from "@/api/contectus";

export default {
  name: "ContectUs",
  data: () => {
    return {
      items: [],
      popupData: null,
      modalShow: false,
      contract_id: 0,
      perPage: 10,
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      selected: 10,
    };
  },
  created() {
    getContectuss(1, this.perPage)
      .then((res) => {
        this.items = (res.data && res.data.data.data) || [];
        this.prev = res.data.data.prev_page_url;
        this.next = res.data.data.next_page_url;
        if (this.prev === null) {
          document.getElementById("prev").classList.add("disable_a_href");
        }
        this.last = res.data.data.last_page_url;
        if (this.next === null) {
          document.getElementById("next").classList.add("disable_a_href");
        }
        this.perPage = res.data.data.per_page;
        this.totalData = res.data.data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  },

  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  methods: {
    getUserId(data) {
      if (this) return (this.current_page - 1) * parseInt(this.perPage) + data;
      return null;
    },
    showData(data) {
      this.popupData = data;
      this.modalShow = true;
    },
    onSelect(event) {
      let value = event.target.value;
      getContectuss(1, value)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.isLoading = false;
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.next = res.data.data.next_page_url;
          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
          }
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    onLoad(value) {
      if (value === "prev") {
        if (this.current_page !== 1) this.current_page = this.current_page - 1;
      } else if (this.current_page < this.totalPage) {
        this.current_page = this.current_page + 1;
      }
      this.onPageLoad(this.current_page);
    },
    onPageLoad(page) {
      getContectuss(page, this.perPage)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.last = res.data.data.next_page_url;
          if (this.prev !== null) {
            document.getElementById("prev").classList.remove("disable_a_href");
          } else {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          if (this.next !== null) {
            document.getElementById("next").classList.remove("disable_a_href");
          } else {
            document.getElementById("next").classList.add("disable_a_href");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      this.current_page = page;
    },
  },
};
</script>

<style scoped>
.disable_a_href {
  pointer-events: none;
}

.active {
  background-color: blue;
}

li {
  cursor: pointer;
}
</style>
