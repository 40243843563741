import request from "@/utils/request";

export function storeContactus(data) {
    return request({
        url: "/api/store_contactus",
        method: "post",
        data
    });
}
export function getContectuss(page, perPage) {
    return request({
        url: "/api/contact_us?perPage=" + perPage + "&page=" + page,
        method: "get"
    });
}

export function getContectUs(id) {
    return request({
        url: "/api/contact_us/" + id,
        method: "get"
    });
}